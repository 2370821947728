import { createRouter, createWebHistory } from 'vue-router';
import HomePage from "@/components/HomePage.vue";
import LoginPage from "@/components/LoginPage.vue";

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
    },
    {
        path: '/',
        name: 'Home',
        component: HomePage,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
