import {sha256} from 'js-sha256';

function generateAESKey(timestamp) {
    const hash = sha256(timestamp);
    const hashHex = hash.toString(hash);
    return new Uint8Array(hashHex.match(/.{2}/g).map(byte => parseInt(byte, 16))).buffer;
}

export async function aesDecrypt(encryptedData, timestamp) {
    try {
        const password = await generateAESKey(timestamp.toString());

        const combined = new Uint8Array(atob(encryptedData).split('').map(c => c.charCodeAt(0)));
        const nonce = combined.slice(0, 12);
        const ciphertext = combined.slice(12);


        const key = await crypto.subtle.importKey(
            'raw',
            password,
            { name: 'AES-GCM' },
            false,
            ['decrypt']
        );

        const decrypted = await crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: nonce
            },
            key,
            ciphertext
        );

        return new TextDecoder().decode(decrypted);
    } catch (err) {
        console.error('Decryption failed:', err);
        throw err;
    }
}

function base64ToArrayBuffer(base64String) {
    const binaryString = atob(base64String);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
    }
    return byteArray.buffer;
}

export async function aesEncrypt(plainText, timestamp) {
    try {
        const password = generateAESKey(timestamp.toString());
        const encoder = new TextEncoder();
        const data = encoder.encode(plainText);

        const nonce = crypto.getRandomValues(new Uint8Array(12));

        const key = await crypto.subtle.importKey(
            'raw',
            password,
            {name: 'AES-GCM'},
            false,
            ['encrypt']
        );

        const encrypted = await crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: nonce
            },
            key,
            data
        );

        const combined = new Uint8Array(nonce.length + encrypted.byteLength);
        combined.set(nonce);
        combined.set(new Uint8Array(encrypted), nonce.length);

        return btoa(String.fromCharCode(...combined));
    } catch (err) {
        console.error('Encryption failed:', err);
        throw err;
    }
}

export async function rsaEncrypt(data, publicKey) {
    try {
        const cleanedPem = publicKey
            .replace(/\r?\n|\r/g, '')
            .replace(/-{5}(BEGIN|END) RSA PUBLIC KEY-{5}/g, '');


        const publicKeyBuffer = base64ToArrayBuffer(cleanedPem);

        const importedKey = await crypto.subtle.importKey(
            'spki',
            publicKeyBuffer,
            {
                name: 'RSA-OAEP',
                hash: { name: 'SHA-256' },
            },
            true,
            ['encrypt']
        );

        const dataBuffer = new TextEncoder().encode(data);

        const encryptedBuffer = await crypto.subtle.encrypt(
            {
                name: 'RSA-OAEP',
                hash: {name: 'SHA-256'},
            },
            importedKey,
            dataBuffer
        );

        return btoa(String.fromCharCode(...new Uint8Array(encryptedBuffer)));

    } catch (error) {
        console.error('Encryption error:', error);
        throw error;
    }
}
