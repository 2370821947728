<template>
    <div v-if="selected" class="data-info">
        <div class="data-info-item">
            <div class="info">
                <div class="data-info-title">
                    <img :src="selected.avatar" alt="">
                </div>
                <div class="data-info-content">
                    <span class="label">{{ selected.name }}</span>
                    <span class="label">{{ selected.uid }}</span>
                </div>
            </div>
            <div v-if="selected.ticker">
                <div v-if="selected.time" class="setting">
                    <span :class="{ 'completed': state, 'not-completed': !state }">{{ state ? "已执行" : "未执行" }}</span>
                    <div class="setting-time" @click="openTimePicker">
                        <span class="label">执行时间: </span>
                        <span class="time">{{ formatCronToReadable(selected.time) }}</span>
                    </div>
                </div>

                <div v-else class="not-time" @click="openTimePicker">
                    <span class="label">自动执行</span>
                    <span class="time">已关闭</span>
                </div>
            </div>

            <div v-else class="not-time" @click="openTimePicker">
                <span class="time">未配置</span>
            </div>
        </div>
        <div class="data-info-data">
            <span class="data-message" v-for="(value, key) in data" :key="key" :class="{ 'completed': value.state, 'not-completed': !value.state }">{{value.name}} : {{value.message}}</span>
        </div>
        <div class="data-buttons">
            <button @click="toggleSelected">关闭</button>
            <button @click="deleteSelected">{{ showSave ? "保存": "删除账号"}}</button>
        </div>
    </div>
    <div v-if="showTimePicker" class="time-picker">
        <div class="time-picker-content">
            <div class="time-buttons">
                <div v-if="showHours" class="hour-buttons">
                    <span v-for="hours in hoursList" :key="hours" @click="selectHour(hours)" :class="{ 'selected': Number(selectedHour) === hours, 'time-item': true }">{{ hours }}</span>
                </div>
                <div v-if="showMinute" class="hour-buttons">
                    <span v-for="minutes in minutesList" :key="minutes" @click="selectMinutes(minutes)" :class="{ 'selected': Number(selectedMinute) === minutes, 'time-item': true }">{{ minutes < 10 ? '0' + minutes : minutes }}</span>
                </div>
            </div>
            <div class="time-picker-actions">
                <button @click="cancelTime">取消</button>
                <button v-if="showMinute" @click="toggleNext">上一步</button>
                <button @click="saveTime">保存</button>
            </div>
        </div>
    </div>
    <LoadCard v-if="loading"/>
    <HintCard v-if="showHint" :message="hintMessage" :success="hintSuccess" />
</template>

<script>
import {ref, toRefs, watch} from "vue";
import $axios from "@/axiosInstance";
import HintCard from "@/components/HintCard.vue";
import LoadCard from "@/components/LoadCard.vue";

export default {
    name: 'SelectedCard',
    components: {LoadCard, HintCard},
    props: {
        selectedData: {
            type: Object,
            required: true,
        },

        showData: {
            type: Boolean,
            required: true,
        },

        showSave: {
            type: Boolean,
            required: true,
        }
    },

    setup(props, {emit}) {
        const { selectedData } = toRefs(props);
        const selected = ref({ ...selectedData.value });
        const data = ref([])
        const state = ref(false)
        const loading = ref(false)
        const showTime = ref(false)
        const showTimePicker = ref(false);
        const showMinute = ref(false);
        const showHours = ref(true);
        const selectedHour = ref('');
        const selectedMinute = ref('');
        const minute = ref('*');
        const hour = ref('*');
        const day = ref('*');
        const month = ref('*');
        const week = ref('*')
        const hoursList = Array.from({ length: 24 }, (_, index) => index);
        const minutesList = Array.from({ length: 60 }, (_, index) => index);
        const showHint = ref(false);
        const hintMessage = ref('');
        const hintSuccess = ref(true);

        const initialize = async () => {
            if (state.value) {
                loading.value = true
                try {
                    const body = {
                        "userName": selected.value.name,
                        "gameName": selected.value.gameName,
                    }

                    let response = await $axios.post("/state", body)
                    if (response.data.status) {
                        const newData = Array.isArray(response.data.data) ?response.data.data : (response.data.data ? [response.data.data] : []);
                        data.value.push(...newData);
                    }
                } catch (error) {
                    console.error("获取失败:", error);
                } finally {
                    loading.value = false
                }
            }
        };

        watch(selectedData, (newVal) => {
            selected.value = { ...newVal };
        });

        watch(state, (newState) => {
            if (newState) {
                initialize();
            }
        });

        const openTimePicker = () => {
            showTimePicker.value = true;
            showMinute.value = false
            showHours.value = true
        };

        const selectHour = (hours) => {
            selectedHour.value = hours
            hour.value = hours;
            showTime.value = true
            showHours.value = false
            showMinute.value = true
        };

        const selectMinutes = (minutes) => {
            selectedMinute.value = minutes
            minute.value = minutes
        };

        const toggleNext = () => {
            showHours.value = true;
            showMinute.value = false;
        };

        const cancelTime = () => {
            selectedHour.value = '';
            selectedMinute.value = '';
            showTime.value = false
            showTimePicker.value = false;
        };

        const formatCronToReadable = (cronTime) => {
            const [minute, hour, dayOfMonth, month, dayOfWeek] = cronTime.split(' ');
            const daysOfWeek = {
                '0': '星期天',
                '1': '星期一',
                '2': '星期二',
                '3': '星期三',
                '4': '星期四',
                '5': '星期五',
                '6': '星期六',
                '7': '星期天'
            };

            const months = {
                '1': '一月',
                '2': '二月',
                '3': '三月',
                '4': '四月',
                '5': '五月',
                '6': '六月',
                '7': '七月',
                '8': '八月',
                '9': '九月',
                '10': '十月',
                '11': '十一月',
                '12': '十二月'
            };

            const now = new Date();
            const currentMinute = now.getMinutes();
            const currentHour = now.getHours();
            const currentDayOfMonth = now.getDate();
            const currentMonth = now.getMonth() + 1;
            const currentDayOfWeek = now.getDay();

            if (dayOfMonth !== '*' && month !== '*') {
                if (currentMonth > parseInt(month) ||
                    (currentMonth === parseInt(month) && currentDayOfMonth > parseInt(dayOfMonth)) ||
                    (currentMonth === parseInt(month) && currentDayOfMonth === parseInt(dayOfMonth) && (currentHour > parseInt(hour) || (currentHour === parseInt(hour) && currentMinute > parseInt(minute))))) {
                    state.value = true;
                }
                return `每年 ${months[month]} ${dayOfMonth}日 ${hour}:${minute}`;
            } else if (dayOfWeek !== '*') {
                if (currentDayOfMonth > parseInt(dayOfMonth) ||
                    (currentDayOfMonth === parseInt(dayOfMonth) && (currentHour > parseInt(hour) || (currentHour === parseInt(hour) && currentMinute > parseInt(minute))))) {
                    state.value = true;
                }
                return `每 ${daysOfWeek[dayOfWeek]} ${hour}:${minute}`;
            } else if (dayOfMonth !== '*') {
                if (currentDayOfWeek > parseInt(dayOfWeek) ||
                    (currentDayOfWeek === parseInt(dayOfWeek) && (currentHour > parseInt(hour) || (currentHour === parseInt(hour) && currentMinute > parseInt(minute))))) {
                    state.value = true;
                }
                return `每月 ${dayOfMonth}日 ${hour}:${minute}`;
            } else if (hour !== '*') {
                if (currentHour > parseInt(hour) ||
                    (currentHour === parseInt(hour) && currentMinute > parseInt(minute))) {
                    state.value = true;
                }
                return `每天 ${hour}:${minute}`;
            } else if (hour === '*') {
                state.value = true;
                return `每 ${minute} 分钟`;
            }
        };

        const toggleSelected = () => {
            emit('update:showData', false)
        };

        const deleteSelected = async () => {
            try {
                const body = {
                    "userName": selected.value.name,
                    "gameName": selected.value.gameName,
                };

                let response = await $axios.delete('/game', {data: body})
                if (response.data.status) {
                    showHintMessage(response.data.message, response.data.status);
                    emit('update:Data', false);
                }
            } catch (error) {
                console.error("删除失败: ", error)
            }
        };

        const saveTime = async () => {
            if (showTime.value) {
                loading.value = true
                let time = `${minute.value} ${hour.value} ${day.value} ${month.value} ${week.value}`;
                selected.value.ticker = true

                try {
                    const body = {
                        "username": selected.value.name,
                        "time": time,
                        "gameName": selected.value.gameName,
                        "ticker":   selected.value.ticker,
                    }

                    let response = await $axios.put("/game", body)
                    if (response.data.status) {
                        selected.value.time = time;
                        emit('update:Data', selected.value);
                    }
                } catch (error) {
                    console.error("更新失败:", error);
                } finally {
                    loading.value = false
                }
            }

            showTimePicker.value = false;
        };

        const showHintMessage = (message, success) => {
            hintMessage.value = message || "未知错误";
            hintSuccess.value = typeof success === 'boolean' ? success : false;
            showHint.value = true;
            setTimeout(() => {
                showHint.value = false;
            }, 3000);
        };

        return {
            selected,
            data,
            state,
            loading,
            showTimePicker,
            showHours,
            showMinute,
            selectedMinute,
            selectedHour,
            hoursList,
            minutesList,
            showHint,
            hintMessage,
            hintSuccess,
            openTimePicker,
            selectHour,
            selectMinutes,
            toggleNext,
            cancelTime,
            formatCronToReadable,
            toggleSelected,
            deleteSelected,
            saveTime,
        }
    }
}

</script>

<style scoped lang="scss">
.data-info {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    padding: 0.5rem;
    border: 1px solid #bdd4fb;
    border-radius: 5px;
    z-index: 1;
    background-color: white;

    .data-info-item {
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        margin-bottom: 0.5rem;

        .info {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 20rem;
            height: 15rem;
            padding: 0.5rem;
            margin-bottom: 0.5rem;
            border: 1px solid #bdd4fb;
            border-radius: 5px;

            img {
                max-width: 12rem;
                max-height: 10rem;
            }

            .data-info-content {
                display: flex;
                flex-direction: column;
                width: 8rem;
                margin-top: -3rem;


                span {
                    border: 1px solid #bdd4fb;
                    border-radius: 5px;
                    padding: 0.5rem;
                    margin-top: 3rem;
                    margin-left: 0.5rem;
                }
            }
        }

        .setting {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            padding: 0.5rem;
            max-height: 15rem;
            margin-left: 0.5rem;
            border: 1px solid #bdd4fb;
            border-radius: 5px;

            .setting-time {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                align-items: center;
                border: 1px solid #bdd4fb;
                width: 10rem;
                height: 7rem;
                border-radius: 5px;
                margin-top: 0.5rem;
                cursor: pointer;

                span {
                    margin-top: 0.5rem;
                }
            }

            &:hover {
                color: #005eeb;
            }

            .completed {
                display: flex;
                border: 1px solid #bdd4fb;
                border-radius: 5px;
                height: 6rem;
                width: 10rem;
                color: #005eeb;
                justify-content: center;
                text-align: center;
                align-items: center;
            }

            .not-completed {
                display: flex;
                height: 6rem;
                width: 10rem;
                border: 1px solid #e09aa9;
                border-radius: 5px;
                color: #c6344a;
                justify-content: center;
                text-align: center;
                align-items: center;
            }
        }

        .not-time {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            padding: 0.5rem;
            height: 15rem;
            width: 10rem;
            margin-left: 0.5rem;
            border: 1px solid #e09aa9;
            border-radius: 5px;
            color: #c6344a;

            span {
                margin-top: 0.5rem;
            }

            &:hover {
                border: 1px solid #bdd4fb;
                color: #005eeb;
            }
        }
    }

    .data-info-data {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 40rem;
        padding: 0.5rem;

        .data-message {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;
            border-radius: 5px;
            font-size: 0.8rem;
            margin: 0.5rem;
        }

        .completed {
            border: 1px solid #bdd4fb;
            color: #005eeb;
        }

        .not-completed {
            border: 1px solid #e09aa9;
            color: #c6344a;
        }
    }

    .data-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            display: flex;
            background-color: white;
            font-size: 0.8rem;
            border: 1px solid #bdd4fb;
            border-radius: 5px;
            padding: 0.5rem;
            cursor: pointer;
        }

        button:hover {
            color: #005eeb;
        }
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 10;

        .loading-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                animation: rotate 2s linear infinite;
            }

            span {
                font-size: 2rem;
                color: red;
                margin-left: 3rem;
                margin-top: 3rem;
                padding: 0.5rem;
            }

            @keyframes rotate {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }
}

.time-picker {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30rem;
    padding: 0.5rem;
    border: 1px solid #bdd4fb;
    border-radius: 5px;
    background-color: white;
    z-index: 2;

    .hour-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        span {
            flex: 0 0 1rem;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 0.5rem;
            border: 1px solid #bdd4fb;
            border-radius: 5px;
            margin: 0.5rem;
            cursor: pointer;
        }

        .selected {
            background-color: #bdd4fb;
            color: #005eeb;
        }

        .time-item:hover {
                color: #005eeb;
        }
    }

    .time-picker-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: white;
            font-size: 0.8rem;
            border: 1px solid #bdd4fb;
            border-radius: 5px;
            padding: 0.5rem;
            cursor: pointer;
        }

        button:hover {
            color: #005eeb;
        }
    }
}
</style>