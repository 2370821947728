<template>
    <div class="login">
        <div class="login-button">
            <span :class="{ 'active': showAccounts }" @click="toggleAccounts">账号登录</span>
            <span :class="{ 'active': showCapCap }" @click="toggleCapCap">验证马登录</span>
        </div>
        <form v-if="showAccounts" @submit.prevent="login" class="login-form">
            <div class="login-input">
                <label for="username">账号: </label>
                <input type="text" id="username" v-model="username" required>
            </div>
            <div class="password-input">
                <label for="password">密码: </label>
                <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required>
                <button type="button" @click="togglePasswordVisibility"><img :src="showPassword ? eye : eyeOff" alt=""></button>
            </div>
            <div class="login-buttons">
                <button @click="toggleLogin">关闭</button>
                <button type="submit">登录</button>
            </div>
        </form>
        <form v-if="showCapCap" @submit.prevent="login" class="login-form">
            <div class="login-input">
                <label for="username">手机号: </label>
                <input type="text" id="username" v-model="username" required>
            </div>
            <div class="captcha">
            <span @click="captcha" :class="{ 'disabled': isCaptchaDisabled }">
                {{ isCaptchaDisabled ? `${countdown}秒后重试` : '获取验证码' }}
            </span>
            </div>
            <div class="login-input">
                <label for="password">验证码: </label>
                <input type="text" id="password" v-model="password" required>
            </div>
            <div class="login-buttons">
                <button @click="toggleLogin">关闭</button>
                <button type="submit">登录</button>
            </div>
        </form>
    </div>
    <HintCard v-if="showHint" :message="hintMessage" :success="hintSuccess" />
</template>

<script>
import {ref} from "vue";
import $axios from "@/axiosInstance";
import {aesDecrypt, aesEncrypt, rsaEncrypt} from "@/assets/js/encryption";
import HintCard from "@/components/HintCard.vue";
import eye from "@/assets/eye.png";
import eyeOff from "@/assets/eye-off.png";

export default {
    components: {
        HintCard,
    },

    props: {
        showLogin: {
            type: Boolean,
            required: true,
        },
    },

    setup(props, {emit}) {
        const showAccounts = ref(true);
        const showCapCap = ref(false);
        const username = ref('');
        const password = ref('');
        const showPassword = ref(false);
        const isCaptchaDisabled = ref(false);
        const countdown = ref(0);
        const showHint = ref(false);
        const hintMessage = ref('');
        const hintSuccess = ref(true);

        const toggleAccounts = () => {
            showAccounts.value = true
            showCapCap.value = false
        };

        const toggleCapCap = () => {
            showCapCap.value = true
            showAccounts.value = false
        };

        const togglePasswordVisibility = () => {
            showPassword.value = !showPassword.value;
        };

        const toggleLogin = () => {
            emit('update:showLogin', false)
        };

        const captcha = async () => {
            if (!username.value) {
                showHintMessage("请输入手机号", false);
                return;
            }
            try {
                startCountdown(60);

                const body = {
                    "userName": username.value,
                    "gameName": "米游社签到",
                }

                let response = await $axios.post("/captcha", body)
                showHintMessage(response.data.message, response.data.status);
            } catch (error) {
                console.error(error)
                isCaptchaDisabled.value = false
            }
        };

        const startCountdown = (seconds) => {
            countdown.value = seconds;
            isCaptchaDisabled.value = true;
            const interval = setInterval(() => {
                countdown.value--;
                if (countdown.value <= 0) {
                    clearInterval(interval);
                    isCaptchaDisabled.value = false;
                }
            }, 1000);
        };

        const login = async () => {
            const Type = showAccounts.value ? "pwd" : "sms"
            try {
                const resp = await $axios.get('/api');
                const time = Date.now();
                const pem = await aesDecrypt(resp.data.data, resp.data.timestamp)
                const user = await rsaEncrypt(await aesEncrypt(username.value, time), pem)
                const passwd = await rsaEncrypt(await aesEncrypt(password.value, time), pem)
                const body = {
                    "id": resp.data.id,
                    "timestamp": time,
                    "userName": user,
                    "passWord": passwd,
                    "typeName": Type,
                    "gameName": "米游社签到",
                }

                let response = await $axios.post("/game", body)
                if (response.data.status) {
                    showHintMessage(response.data.message, response.data.status);
                    emit('update:Data', response.data.data);
                } else {
                    showHintMessage(response.data.message, response.data.status);
                }

            } catch (error) {
                console.error("获取失败:", error);
            }
        };

        const showHintMessage = (message, success) => {
            hintMessage.value = message || "未知错误";
            hintSuccess.value = typeof success === 'boolean' ? success : false;
            showHint.value = true;
            setTimeout(() => {
                showHint.value = false;
            }, 3000);
        };

        return {
            eye,
            eyeOff,
            showAccounts,
            showCapCap,
            username,
            password,
            showPassword,
            isCaptchaDisabled,
            countdown,
            showHint,
            hintMessage,
            hintSuccess,
            toggleAccounts,
            toggleCapCap,
            togglePasswordVisibility,
            captcha,
            login,
            toggleLogin,
        }
    }
}

</script>

<style scoped lang="scss">
.login {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    border: 1px solid #bdd4fb;
    border-radius: 5px;
    background-color: white;

    .login-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
            display: flex;
            justify-content: center;
            padding: 0.5rem;
            border: 1px solid #bdd4fb;
            border-radius: 5px;
            margin: 0.5rem;
            cursor: pointer;
        }

        span:hover {
            color: #005eeb;
        }

        span.active {
            background-color: #bdd4fb;
            color: #005eeb;
        }

    }

    .login-form {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .login-input {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            padding: 1rem;

            label {
                margin-right: 1rem;
            }

            input {
                font-size: 1.2rem;
                padding: 0.5rem;
                border: 1px solid #bdd4fb;
                border-radius: 5px;
                color: transparent;
                background: linear-gradient(45deg, #ff6b6b, #f0e130, #8ec5fc);
                background-clip: text;
                -webkit-background-clip: text;

                &:focus {
                    border-color: #005eeb;
                    outline: none;
                }
            }

            button {
                margin-left: 0.5rem;
                font-size: 1rem;
                padding: 0.5rem 1rem;
                cursor: pointer;
                background-color: white;
                border: 1px solid #bdd4fb;
                border-radius: 5px;

                &:hover {
                    color: #003d99;
                }
            }
        }

        .captcha {
            display: flex;
            justify-content: right;

            span {
                padding: 0.5rem;
                border: 1px solid #bdd4fb;
                border-radius: 5px;
                margin-right:1rem;
                cursor: pointer;
            }

            .disabled {
                pointer-events: none;
                color: grey;
            }
        }

        .password-input {
            position: relative;
            display: flex;
            justify-content: left;
            align-items: center;
            padding: 1rem;

            label {
                margin-right: 1rem;
            }

            input {
                flex: 1;
                font-size: 1.2rem;
                padding: 0.5rem;
                border: 1px solid #bdd4fb;
                border-radius: 5px;
                color: transparent;
                background: linear-gradient(45deg, #ff6b6b, #f0e130, #8ec5fc);
                background-clip: text;
                -webkit-background-clip: text;

                &:focus {
                    border-color: #005eeb;
                    outline: none;
                }
            }

            button {
                position: absolute;
                right: 1.5rem;
                top: 50%;
                transform: translateY(-50%);
                background: none;
                border: none;
                cursor: pointer;
            }
        }

        .login-buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;

            button {
                border: 1px solid #bdd4fb;
                border-radius: 5px;
                background-color: white;
                font-size: 1rem;
                padding: 0.5rem;
                margin: 0.5rem;
                cursor: pointer;
            }

            button:hover {
                color: #005eeb;
            }
        }
    }
}

</style>