<template>
    <div class="main-login">
        <div class="login-style">
            <form @submit.prevent="handleSubmit" class="form-style">
                <div class="login-input">
                    <label for="username">账号: </label>
                    <input type="text" id="username" v-model="username" required>
                </div>
                <div class="password-input">
                    <label for="password">密码: </label>
                    <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required>
                    <button type="button" @click="togglePasswordVisibility"><img :src="showPassword ? eyeIcon : eyeOffIcon" alt=""></button>
                </div>
                <div class="login-buttons">
                    <button type="submit">{{showLogin? "登录": "注册" }}</button>
                </div>
                <div class="register">
                    <button type="button" @click="toggleLogin">{{showLogin? "注册": "登录" }}</button>
                </div>
            </form>
            <LoadCard v-if="loading"/>
        </div>
        <HintCard v-if="showHint" :message="hintMessage" :success="hintSuccess" />
    </div>
</template>

<script>
import {ref} from "vue";
import $axios from "@/axiosInstance";
import eyeIcon from "@/assets/eye.png";
import eyeOffIcon from "@/assets/eye-off.png";
import {aesDecrypt, rsaEncrypt, aesEncrypt} from "@/assets/js/encryption";
import HintCard from '@/components/HintCard.vue';
import { useRouter } from 'vue-router';
import LoadCard from "@/components/LoadCard.vue";

export default {
    name: "LoginPage",
    components: {
        LoadCard,
        HintCard
    },

    setup() {
        const router = useRouter();
        const loading = ref(false)
        const showLogin = ref(true)
        const username = ref(null);
        const password = ref(null);
        const showPassword = ref(false);
        const showHint = ref(false);
        const hintMessage = ref('');
        const hintSuccess = ref(true);

        const togglePasswordVisibility = () => {
            showPassword.value = !showPassword.value;
        };

        const toggleLogin = () => {
            showLogin.value = !showLogin.value;
        }

        const toggleAccounts = () => {
            username.value = null;
            password.value = null;
        }

        const showHintMessage = (message, success) => {
            hintMessage.value = message;
            hintSuccess.value = success;
            showHint.value = true;
            setTimeout(() => {
                showHint.value = false;
            }, 3000);
        };

        const login = async (body) => {
            try {
                let response = await $axios.post('/login', body);

                if (response.data.status) {
                    toggleAccounts();
                    showHintMessage("登录成功", true);
                    await router.push('/');
                } else {
                    showHintMessage("账号或密码错误", false);
                }
            } catch(err) {
                showHintMessage("登录时发生错误", false);
            }
        };

        const register = async (body) => {
            loading.value = true
            try {
                let response = await $axios.post('/api', body);

                if (response.data.status) {
                    toggleAccounts();
                    showHintMessage(response.data.message, true);
                } else {
                    showHintMessage("注册失败: " + response.data.message, false);
                }
            } catch(err) {
                showHintMessage("注册时发生错误", false);
            } finally {
                loading.value = false
            }
        };

        const handleSubmit = async () => {
            loading.value = true
            try {
                const response = await $axios.get('/api');
                const time = Date.now();
                const pem = await aesDecrypt(response.data.data, response.data.timestamp)
                const user = await rsaEncrypt(await aesEncrypt(username.value, time), pem)
                const passwd = await rsaEncrypt(await aesEncrypt(password.value, time), pem)
                const body = {
                    "id": response.data.id,
                    "timestamp": time,
                    "userName": user,
                    "passWord": passwd,
                }

                if (showLogin.value) {
                    await login(body);
                } else {
                    await register(body);
                }
            } catch (error) {
                console.error(error);
            } finally {
                loading.value = false
            }
        };

        return {
            eyeIcon,
            eyeOffIcon,
            loading,
            showLogin,
            username,
            password,
            showPassword,
            login,
            togglePasswordVisibility,
            toggleLogin,
            handleSubmit,
            showHint,
            hintMessage,
            hintSuccess,
        };
    },
};
</script>

<style lang="scss">
.main-login {
    display: flex;
    flex-direction: column;
    cursor: default;

    .login-style {
        display: flex;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-direction: column;
        justify-content: center;
        padding: 0.5rem;
        border: 1px solid #bdd4fb;
        border-radius: 5px;

        .form-style {
            display: flex;
            flex-direction: column;
            margin: 1rem;
        }
    }
}

.form-style {
    .login-input {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        padding: 1rem;

        label {
            margin-right: 1rem;
        }

        input {
            font-size: 1.2rem;
            padding: 0.5rem;
            border: 1px solid #bdd4fb;
            border-radius: 5px;
            color: transparent;
            background: linear-gradient(45deg, #ff6b6b, #f0e130, #8ec5fc);
            background-clip: text;
            -webkit-background-clip: text;

            &:focus {
                border-color: #005eeb;
                outline: none;
            }
        }

        button {
            margin-left: 0.5rem;
            font-size: 1rem;
            padding: 0.5rem 1rem;
            cursor: pointer;
            background-color: white;
            border: 1px solid #bdd4fb;
            border-radius: 5px;

            &:hover {
                color: #003d99;
            }
        }
    }

    .password-input {
        position: relative;
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 1rem;

        label {
            margin-right: 1rem;
        }

        input {
            flex: 1;
            font-size: 1.2rem;
            padding: 0.5rem;
            border: 1px solid #bdd4fb;
            border-radius: 5px;
            color: transparent;
            background: linear-gradient(45deg, #ff6b6b, #f0e130, #8ec5fc);
            background-clip: text;
            -webkit-background-clip: text;

            &:focus {
                border-color: #005eeb;
                outline: none;
            }
        }

        button {
            position: absolute;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
            cursor: pointer;
        }
    }

    .register {
        display: flex;
        justify-content: right;
        margin-top: 1rem;

        button {
            background-color: white;
            padding: 0.5rem;
            border: 1px solid #bdd4fb;
            border-radius: 5px;
        }
    }

    .login-buttons {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        button {
            margin-left: 0.5rem;
            font-size: 1rem;
            padding: 0.5rem 1rem;
            cursor: pointer;
            width: 20rem;
            background-color: white;
            border: 1px solid #bdd4fb;
            border-radius: 5px;

            &:hover {
                color: #003d99;
            }
        }
    }
}

</style>
