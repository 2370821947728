<template>
    <transition name="fade">
        <div v-if="visible" :class="hintClass" class="hint-card">
            <p>{{ message }}</p>
        </div>
    </transition>
</template>

<script>
import { ref, computed, watch } from 'vue';

export default {
    name: 'HintCard',
    props: {
        message: {
            type: String,
            required: true
        },
        success: {
            type: Boolean,
            required: true
        },
        duration: {
            type: Number,
            default: 1500
        }
    },
    setup(props) {
        const visible = ref(true);

        const hintClass = computed(() => {
            return props.success ? 'hint-card-success' : 'hint-card-failure';
        });

        watch(visible, (newVal) => {
            if (newVal) {
                setTimeout(() => {
                    visible.value = false;
                }, props.duration);
            }
        }, { immediate: true });

        return {
            visible,
            hintClass
        };
    }
};
</script>

<style scoped lang="scss">
.hint-card {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    border: 1px solid #bdd4fb;
    border-radius: 5px;
    z-index: 1000;

    p {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        text-align: center;
    }
}

.hint-card-success {
    background-color: #bdd4fb;
    color: #005eeb;
    border: 1px solid #bdd4fb;
}

.hint-card-failure {
    background-color: rgba(255, 107, 107, 0.55);
    color: #f41717;
    border: 1px solid rgba(255, 107, 107, 0.55);
}

</style>