<template>
    <div class="main-container">
        <div class="main-head">
            <button @click="toggleLog">日志</button>
            <button>主题</button>
            <div class="main-theme">
            </div>
        </div>
    </div>
    <div class="main-data">
        <div class="main-info" v-for="(value, key) in data" :key="key" @click="toggleSelected(value)">
            <img :src="value.avatar" alt="">
            <span class="label">{{value.name}}</span>
            <span class="label">UID: {{value.uid}}</span>
        </div>

        <button class="add-data" @click="toggleLogin"></button>
    </div>
    <div v-if="showLog" class="log-box">
        <div class="log-content">
            这里是日志内容。
        </div>
    </div>
    <SelectedCard v-if="showData && selectedData" :selectedData="selectedData" :showData="showData" :showSave="showSave" @update:showData="updateShowData" @update:Data="updateSelected"/>
    <LoginCard v-if="showLogin" :showLogin="showLogin" @update:showLogin="updateLogin" @update:Data="handleUpdateData"/>
    <HintCard v-if="showHint" :message="hintMessage" :success="hintSuccess" />
</template>

<script>
import {onMounted, ref} from 'vue';
import $axios from "@/axiosInstance";
import HintCard from "@/components/HintCard.vue";
import LoginCard from "@/components/GameLoginCard.vue"
import SelectedCard from "@/components/SelectedCard.vue";

export default {
    name: "HomePage",
    components: {
        HintCard,
        LoginCard,
        SelectedCard,
    },

    setup() {
        const loading = ref(false)
        const showLog = ref(false);
        const showData = ref(false);
        const showSave = ref(false);
        const showLogin = ref(false);
        const selectedData = ref(null);

        const data = ref('')
        const showHint = ref(false);
        const hintMessage = ref('');
        const hintSuccess = ref(true);

        const fetchUserData = async () => {
            try {
                const response = await $axios.get('/game');
                if (response.data.status) {
                    data.value = response.data.data;
                } else {
                    showHintMessage(response.data.message, response.data.status);
                }
            } catch (error) {
                console.error("获取数据失败:", error);
            }
        };

        const toggleLog = () => {
            showLog.value = !showLog.value;
        };

        const toggleLogin = () => {
            showLogin.value = !showLogin.value
        };

        const toggleSelected = (value, bool) => {
            if (value) {
                if (bool) {
                    showSave.value = bool
                }
                selectedData.value = value;
                showData.value = !showData.value
            }
        };

        const selectData = (value) => {
            selectedData.value = value;
        };

        const formatCronToReadable = (cronTime) => {
            const [minute, hour] = cronTime.split(' ');
            return `${hour}:${minute}`;
        };

        const showHintMessage = (message, success) => {
            hintMessage.value = message;
            hintSuccess.value = success;
            showHint.value = true;
            setTimeout(() => {
                showHint.value = false;
            }, 3000);
        };

        const updateShowData = (value) => {
            showData.value = value;
        };

        const updateSelected = (value) => {
            if (value) {
                const index = data.value.findIndex(item => item === selectedData.value);
                data.value[index] = value;
            } else {
                data.value = data.value.filter(item => item !== selectedData.value)
                showSave.value = false
                showData.value = false;
            }
        };

        const updateLogin = (value) => {
            showLogin.value = value;
        };

        const handleUpdateData = (value) => {
            showLogin.value = false;
            data.value.push(value);
            toggleSelected(value, true);
        };

        onMounted(() => {
            fetchUserData();
        });

        return {
            loading,
            showLog,
            showData,
            showSave,
            toggleSelected,
            showLogin,
            data,
            toggleLog,
            toggleLogin,
            selectedData,
            selectData,
            showHint,
            hintMessage,
            hintSuccess,
            formatCronToReadable,
            updateShowData,
            updateSelected,
            updateLogin,
            handleUpdateData,
        };
    }
};
</script>

<style lang="scss">
.main-container {
    display: flex;
    flex-direction: column;
    cursor: default;

    .main-head {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 1px solid #bdd4fb;
        padding: 0.5rem 2rem;

        button {
            padding: 0.5rem 0.5rem;
            margin-right: 2rem;
            border: 1px solid #bdd4fb;
            border-radius: 5px;
            background-color: white;

            cursor: pointer;
        }

        button:hover {
            border: 1px solid #bdd4fb;
            background-color: #ffffff;
            color: #005eeb;
        }

    }
}

.main-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.5rem;

    .main-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 8.5rem;
        height: 8.5rem;
        border: 1px solid #bdd4fb;
        border-radius: 5px;
        color: #005eeb;
        margin: 0.5rem;
        cursor: pointer;

        img {
            max-width: 5rem;
            max-height: 5rem;
        }
    }

    button {
        width: 8.5rem;
        height: 8.5rem;
        padding: 0.5rem;
        border: 1px solid #bdd4fb;
        border-radius: 5px;
        background-color: white;
        cursor: pointer;
    }

    .add-data {
        width: 8.5rem;
        height: 8.5rem;
        padding: 0.5rem;
        border: 1px solid #bdd4fb;
        border-radius: 5px;
        background-color: white;
        cursor: pointer;
        position: relative;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
            height: 2px;
            background-color: #bdd4fb;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(90deg);
        }

    }
}

.log-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none;

    .log-content {
        border: 1px solid #ccc;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        max-width: 300px;
    }
}

</style>