import axios from 'axios';
import router from "@/router";

const $axios = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    "withCredentials": true,
})

$axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            const currentRoute = router.currentRoute.value.fullPath;
            router.push({ path: '/login', query: { redirect: currentRoute } });
        }
        return Promise.reject(error);
    }
);

export default $axios;
